import React, { ReactNode } from 'react';
import TabPanel from '@material-ui/lab/TabPanel';
import { TabsLayout } from '@wings-shared/layout';
import { observer } from 'mobx-react';
import { HealthVendorContactModel } from '../../../Shared';
import { HealthVendorContact } from '../index';
import { Field } from 'mobx-react-form';
import { IClasses, IOptionValue, UnsubscribableComponent } from '@wings-shared/core';

type Props = {
  classes?: IClasses;
  isEditable: boolean;
  contacts: HealthVendorContactModel[];
  onUpdate: (contacts: HealthVendorContactModel, removeContact?: boolean) => void;
  onContactEditing: (isEditing: boolean) => void;
  getField: (fieldKey: string) => Field;
  onChange: (value: IOptionValue, fieldKey: string) => void;
};
@observer
export class HealthVendorTabs extends UnsubscribableComponent<Props> {
  private readonly tabs = [ 'Contact Info' ];
  render(): ReactNode {
    const { contacts, onUpdate, onContactEditing, isEditable, getField, onChange } = this.props;
    return (
      <TabsLayout headingTitle="" tabs={this.tabs} activeTab={this.tabs[0]}>
        <TabPanel key={0} value={this.tabs[0]}>
          <HealthVendorContact
            isEditable={isEditable}
            contacts={contacts}
            onUpdate={(contact, removeContact) => onUpdate(contact, removeContact)}
            onContactEditing={isEditing => onContactEditing(isEditing)}
            getField={(fieldKey: string) => getField(fieldKey)}
            onChange={(value, fieldKey) => onChange(value, fieldKey)}
          />
        </TabPanel>
      </TabsLayout>
    );
  }
}

export default HealthVendorTabs;
