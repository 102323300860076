import React from 'react';
import { INavigationLink } from '@wings-shared/layout';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import {
  WarningIcon,
  SettingIcon,
  BriefCaseIcon,
  InfoIcon,
  HistoryIcon,
  EntranceIcon,
  HealthVendorIcon,
  QuarantineIcon,
  VaccineIcon,
  StayHomeIcon,
  ExitIcon,
  ClockIcon,
} from '@uvgo-shared/icons';
import { Utilities } from '@wings-shared/core';

export const sidebarOptions = (isHealthAuth: boolean, isDisabled: boolean = true): INavigationLink[] =>
  isHealthAuth ? healthAuthorizationOptions(isDisabled) : restrictionsOptions;

const restrictionsOptions: INavigationLink[] = [
  {
    to: '',
    title: 'Health Authorization',
    icon: <CreateNewFolderOutlinedIcon />,
  },
  {
    to: 'health-vendor',
    title: 'Health Vendor',
    isHidden: false,
    icon: <HealthVendorIcon />,
  },
  {
    to: 'schedule-restrictions',
    title: 'Schedule Restrictions',
    icon: <ClockIcon />,
  },
  {
    to: 'aircraft-operator-restrictions',
    title: 'Aircraft Operator Restrictions',
    icon: <WarningIcon />,
  },
  {
    to: 'settings',
    title: 'Settings',
    icon: <SettingIcon />,
  },
];

const healthAuthorizationOptions = (isDisabled: boolean): INavigationLink[] => [
  {
    to: '',
    title: 'Overview',
    icon: <BriefCaseIcon />,
  },
  {
    to: 'general',
    title: 'General Requirement',
    icon: <InfoIcon />,
    isDisabled,
  },
  {
    to: 'entry-requirement',
    title: 'Entry Requirement',
    icon: <EntranceIcon />,
    isDisabled,
  },
  {
    to: 'quarantine-requirement',
    title: 'Quarantine Requirement',
    icon: <QuarantineIcon />,
    isDisabled,
  },
  {
    to: 'vaccination-requirement',
    title: 'Vaccination Requirement',
    icon: <VaccineIcon />,
    isDisabled,
  },
  {
    to: 'stay-requirement',
    title: 'Stay Requirement',
    icon: <StayHomeIcon />,
    isDisabled,
  },
  {
    to: 'exit-requirement',
    title: 'Exit Requirement',
    icon: <ExitIcon />,
    isDisabled,
  },
  {
    to: 'domestic-measure',
    title: 'Domestic Measure',
    icon: <ExitIcon />,
    isDisabled,
  },
  {
    to: 'traveled-history',
    title: 'Traveled History',
    icon: <HistoryIcon />,
    isDisabled,
  },
  {
    to: 'change-records',
    title: 'Change Records',
    icon: <ChangeHistoryIcon />,
    isDisabled,
  },
];

export const updateRestrictionSidebarOptions = (tabQuery: string): INavigationLink[] =>
  Utilities.updateSidebarOptions(restrictionsOptions, tabQuery);
