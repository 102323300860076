import React, { ElementType } from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { HealthAuthStore, RestrictionRootStore } from './Modules/Shared';
import Root from './App';
import { ErrorBoundaryDefault, Layout, ThemeContainer } from '@wings-shared/layout';
import { ConfigureOkta } from '@wings/shared';

const lifeCycles = singleSpaReact({
  React,
  ReactDOM,
  loadRootComponent: (p: any) =>
    new Promise<ElementType>((resolve, reject) =>
      resolve(() => {
        ConfigureOkta.start()
        return (
          <ThemeContainer store={{ ...HealthAuthStore, ...RestrictionRootStore }} appName="restrictions">
            <Layout>
              <Root />
            </Layout>
          </ThemeContainer>
        );
      })
    ),
  errorBoundaryClass: ErrorBoundaryDefault,
});

export const { bootstrap, mount, unmount } = lifeCycles;
export default lifeCycles;
