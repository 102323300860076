import React, { Component, ReactNode } from 'react';
import { inject } from 'mobx-react';
import { Settings, HealthAuth, HealthVendor } from './Modules';
import { IBaseModuleProps } from '@wings/shared';
import {
  AuthStore,
  SettingsModuleSecurity,
  ModulePermissions,
  MODULE_ACTIONS,
  USER_GROUP,
} from '@wings-shared/security';
import { Routes, Route } from 'react-router-dom';
import { HealthVendorEditor } from './Modules/HealthVendor';
import { ScheduleRestrictions, UpsertScheduleRestrictions } from './Modules/ScheduleRestrictions';
import { AircraftOperatorRestriction, UpsertAircraftOperatorRestriction } from './Modules/AircraftOperatorRestriction';
import { RestrictionModuleSecurity, sidebarOptions } from './Modules/Shared';
import { NotFoundPage, SearchStore } from '@wings-shared/core';
import { ProtectedRoute } from '@wings-shared/layout';

const permissions: ModulePermissions = {
  [MODULE_ACTIONS.EDIT]: [ USER_GROUP.QRG_ADMIN, USER_GROUP.ADMIN ],
};

const HealthAuthLazyRoutes = React.lazy(() =>
  import(
    /* webpackChunkName: "healthAuthorization" */
    './Modules/HealthAuthorization/Components/HealthAuthorizationRoutes/HealthAuthorizationRoutes'
  )
);

@inject('sidebarStore')
export default class RestrictionApp extends Component<IBaseModuleProps> {
  private readonly redirectPath: string = '/restrictions';
  constructor(props) {
    super(props);
    this.props.sidebarStore?.setNavLinks(sidebarOptions(false), this.props.basePath);
    // Needs to set permissions after lazy loading
    SettingsModuleSecurity.updatePermissions(permissions);
    RestrictionModuleSecurity.init();
    AuthStore.configureAgGrid();
  }

  // 81904 clear search store on application unmount
  componentWillUnmount(): void {
    SearchStore.clearSearch();
  }

  private get hasPermission(): boolean {
    return AuthStore.user?.isAdminUser || AuthStore.user?.isQRGAdmin;
  }

  public render(): ReactNode {
    return (
      <Routes>
        <Route path="restrictions/*">
          <Route index element={<HealthAuth />} />
          <Route path="settings" element={<Settings />} />
          <Route
            path="health-authorization/:viewMode/*"
            key="healthAuthorizationAdd"
            element={
              <ProtectedRoute
                element={
                  <HealthAuthLazyRoutes
                    basePath={`${this.props.basePath}/health-authorization`}
                    key={'health-authorization-add'}
                  />
                }
                hasPermission={RestrictionModuleSecurity.isEditable}
                redirectPath={this.redirectPath}
              />
            }
          />
          <Route
            path="health-authorization/:id/:viewMode/*"
            element={
              <HealthAuthLazyRoutes
                basePath={`${this.props.basePath}/health-authorization`}
                key={'health-authorization-edit'}
              />
            }
          />
          <Route path="health-vendor" element={<HealthVendor />} />
          <Route
            path="health-vendor/:mode"
            element={
              <ProtectedRoute
                element={<HealthVendorEditor key={'health-vendors'} />}
                hasPermission={this.hasPermission}
                redirectPath={this.redirectPath}
              />
            }
          />
          <Route path="health-vendor/:id/:mode" element={<HealthVendorEditor key={'health-vendor-edit'} />} />
          <Route path="schedule-restrictions" element={<ScheduleRestrictions />} />
          <Route
            path="schedule-restrictions/:viewMode"
            element={<UpsertScheduleRestrictions key={'schedule-restrictions'} />}
          />
          <Route
            path="schedule-restrictions/:scheduleRestrictionId/:viewMode"
            key="schedule-restrictions-details"
            element={<UpsertScheduleRestrictions key={'schedule-restrictions-edit'} />}
          />
          <Route path="aircraft-operator-restrictions" element={<AircraftOperatorRestriction />} />
          <Route
            path="aircraft-operator-restrictions/:viewMode"
            element={<UpsertAircraftOperatorRestriction key={'aircraft-operator-restriction'} />}
          />
          <Route
            path="aircraft-operator-restrictions/:id/:viewMode"
            key="aircraft-operator-restrictions"
            element={<UpsertAircraftOperatorRestriction key="aircraft-operator-restrictions-edit" />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    );
  }
}
