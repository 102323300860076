import React, { FC } from 'react';
import TabPanel from '@material-ui/lab/TabPanel';
import { TabsLayout } from '@wings-shared/layout';
import { observer } from 'mobx-react';
import { HealthVendorContactModel } from '../../../Shared';
import { HealthVendorContactV2 } from '../index';
import { Field } from 'mobx-react-form';
import { IOptionValue } from '@wings-shared/core';

type Props = {
  isEditable: boolean;
  contacts: HealthVendorContactModel[];
  onUpdate: (contacts: HealthVendorContactModel, removeContact?: boolean) => void;
  onContactEditing: (isEditing: boolean) => void;
  getField: (fieldKey: string) => Field;
  onChange: (value: IOptionValue, fieldKey: string) => void;
};

const HealthVendorTabsV2: FC<Props> = ({
  contacts,
  onUpdate,
  onContactEditing,
  isEditable,
  getField,
  onChange,
}: Props) => {
  const tabs = [ 'Contact Info' ];

  return (
    <TabsLayout headingTitle="" tabs={tabs} activeTab={tabs[0]}>
      <TabPanel key={0} value={tabs[0]}>
        <HealthVendorContactV2
          isEditable={isEditable}
          contacts={contacts}
          onUpdate={(contact, removeContact) => onUpdate(contact, removeContact)}
          onContactEditing={isEditing => onContactEditing(isEditing)}
          getField={getField}
          onChange={onChange}
        />
      </TabPanel>
    </TabsLayout>
  );
};

export default observer(HealthVendorTabsV2);
